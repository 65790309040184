// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	$('.menu-toggle').on('click', function(){
		$('.hamburger').toggleClass('is-active');
		$('.menu-toggle .txt').toggleClass('is-active');
		$('.mobile-menu').toggleClass('is-active');
		$('html').toggleClass('locked');
	});
	$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
	});
	$('.mobile-menu .login.active').on('click', function(){
		$('.login-form').toggle();
	})
	$('.login-form .close-login-form').on('click', function(){
		$('.login-form').toggle();
	})
	
	
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100
		});
	
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);
	
	$('#newsletter_form input[name=email]').focus( function(){
		$('#newsletter_form input[name=cpt]').val('425');
	});

	//CONTACT FORM
	$('#newsletter_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {

			console.log("form is valid");
			$('#newsletter_form .loading').css('display','inline-block');
			var that = $('#newsletter_form'),
			url = that.attr("action"),
			method = that.attr("method"),
			data = {};
			that.find("[name]").each( function() {
				var that = $(this),
					name = that.attr("name"),
					value = that.val();
					data[name] = value;
			});
			
			if($('#newsletter_form input[name=cpt]').val() == '425') {
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log('success');
						$('#newsletter_form').hide(); 
						$('.newsletter .successMessage').show(); 

					},
					error: function(response){
						$('#newsletter_form .loading').hide();
					}
				});
			} else {
				$('#newsletter_form .loading').hide();
			}
		});
	
	$('#login_form input[name=username]').focus( function(){
		$('#login_form input[name=cpt]').val('425');
	});

	//LOGIN FORM
	$('#login_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {

			console.log("form is valid");
			$('#login_form .loading').css('display','inline-block');
			var that = $('#login_form'),
			url = that.attr("action"),
			method = that.attr("method"),
			data = {};
			that.find("[name]").each( function() {
				var that = $(this),
					name = that.attr("name"),
					value = that.val();
					data[name] = value;
			});
			
			if($('#login_form input[name=cpt]').val() == '425') {
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log(response);
						if(response == 'success'){
							$('.login-form .successMessage').text('You are logged in, refreshing page...'); 
							$('.login-form .successMessage').show();
							$('#login_form .loading').hide();
							location.reload();
						} else if(response == 'fail'){
							$('.login-form .successMessage').text('Wrong username or password, try again'); 
							$('.login-form .successMessage').show(); 
							$('#login_form .loading').hide();
						} else {
							$('.login-form .successMessage').text('Error, try again'); 
							$('.login-form .successMessage').show(); 
							$('#login_form .loading').hide();
						}
						
					},
					error: function(response){
						$('#login_form .loading').hide();
						console.log(response);
						$('.login-form .successMessage').text('Error, try again'); 
						$('.login-form .successMessage').show(); 
					}
				});
			} else {
				$('#login_form .loading').hide();
				$('.login-form .successMessage').hide(); 
			}
		});
	


	
	
});
